import { graphql } from "gatsby";
import { createContext } from "react";

const SeoContext = createContext();
export default SeoContext

export const query = graphql`
    fragment Settings on SanitySiteSettings {
        title
        description
        url
        logo {
            asset {
                url
            }
        }
        address {
            streetAndNumber
            country
            city
            zip
            geolocation {
                lat
                lng
            }
        }
        defaultImage {
            asset {
                url
            }
        }
    }
`