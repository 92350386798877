import React, { useContext } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";
import SeoContext from "./context/seoContext";

const SEO = ({ description, lang, meta, keywords, title, image, children }) => {
  const context = useContext(SeoContext);

  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const metaDescription = description || (data.site && data.site.description) || "";
        const siteAuthor = (data.site && data.site.author && data.site.author.name) || "";
        const metaImage =
          image && image.asset ? imageUrlFor(buildImageObj(image)).width(1200).url() : "";

        return (
          <Helmet
            htmlAttributes={{ lang }}
            title={context.pageTitle || "Siguna"}
            titleTemplate={"%s"}
            meta={[
              {
                name: "description",
                content: metaDescription,
              },
              {
                property: "og:title",
                content: title,
              },
              {
                property: "og:description",
                content: metaDescription,
              },
              {
                property: "og:type",
                content: "website",
              },
              {
                property: "og:image",
                content: metaImage,
              },
              {
                name: "twitter:card",
                content: "summary",
              },
              {
                name: "twitter:creator",
                content: siteAuthor,
              },
              {
                name: "twitter:title",
                content: title,
              },
              {
                name: "twitter:description",
                content: metaDescription,
              },
            ]
              .concat(
                keywords && keywords.length > 0
                  ? {
                      name: "keywords",
                      content: keywords.join(", "),
                    }
                  : []
              )
              .concat(meta)}
          >
            <script>
              {`window.dataLayer = window.dataLayer || [];
              function gtag() {
              dataLayer.push(arguments);
              }
              gtag('consent', 'default', {
              ad_storage: 'denied',
              analytics_storage: 'denied',
              wait_for_update: 500,
              });
              gtag('set', 'ads_data_redaction', true);`}
            </script>
            <script
              id="CookieConsent"
              src="https://policy.app.cookieinformation.com/uc.js"
              data-culture="EN"
              type="text/javascript"
            ></script>
            <script type="application/ld+json">
              {`{
                  "@context": "https://schema.org",
                  "@type": "LocalBusiness",
                  "name":"${context?.title}",
                  "logo":"${context?.logo?.asset?.url}",
                  "image":"${context?.defaultImage?.asset?.url}",
                  "description":"${context?.description}",
                  "url":"${context?.url}",
                  "address": {
                    "@type": "PostalAddress",
                    "streetAddress": "${context?.address?.streetAndNumber}",
                    "addressLocality": "${context?.address?.city}",
                    "postalCode": "${context?.address?.zip}",
                    "addressCountry": "${context?.address?.country}"
                  },
                  "geo": {
                    "@type": "GeoCoordinates",
                    "latitude": ${context?.address?.geolocation?.lat},
                    "longitude": ${context?.address?.geolocation?.lng}
                  }
                }`}
            </script>
            {children}
          </Helmet>
        );
      }}
    />
  );
};

SEO.defaultProps = {
  lang: "en",
  meta: [],
  keywords: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site: sanitySiteSettings(_id: { eq: "siteSettings" }) {
      title
      description
      keywords
      author {
        name
      }
    }
  }
`;
